/**
 * 钉钉选人
 */
import myPublic from './public'
import * as dd from 'dingtalk-jsapi';
const corpId =  env.corp_id;
//PC端和移动端同用一个选人接口
export default{
    peoplePicker(multiple,users,callback,maxUsers,pickedDepartments,disabledUsers,disabledDepartments,requiredUsers,requiredDepartments,responseUserOnly){
        let self = this;
        dd.ready(function(){
        	self.complexPicker(multiple,users,callback,maxUsers,pickedDepartments,disabledUsers,disabledDepartments,requiredUsers,requiredDepartments,responseUserOnly);
        });
    },
    //移动端选人接口
    complexPicker(multiple,users,callback,maxUsers,pickedDepartments,disabledUsers,disabledDepartments,requiredUsers,requiredDepartments,responseUserOnly){
        dd.biz.contact.complexPicker({
            title:"人员选择",            //标题
            corpId:corpId,              //企业的corpId
            multiple:multiple,            //是否多选
            limitTips:"超出了",          //超过限定人数返回提示
            maxUsers:(maxUsers ? maxUsers : 1000),            //最大可选人数
            pickedUsers:users,            //已选用户
            pickedDepartments:(pickedDepartments ? pickedDepartments : []),          //已选部门
            disabledUsers:(disabledUsers ? disabledUsers : []),            //不可选用户
            disabledDepartments:(disabledDepartments ? disabledDepartments : []),        //不可选部门
            requiredUsers:(requiredUsers ? requiredUsers : []),            //必选用户（不可取消选中状态）
            requiredDepartments:(requiredDepartments ? requiredDepartments : []),        //必选部门（不可取消选中状态）
            appId:4867,              //微应用的Id
            permissionType:"GLOBAL",          //选人权限，目前只有GLOBAL这个参数
            responseUserOnly:(responseUserOnly ? responseUserOnly : true),        //返回人，或者返回人和部门
            onSuccess: (result)=>{
                /**
                {
                    selectedCount:1,                              //选择人数
                    users:[{"name":"","avatar":"","emplId":""}]，//返回选人的列表，列表中的对象包含name（用户名），avatar（用户头像），emplId（用户工号）三个字段
                    departments:[{"id":,"name":"","number":}]//返回已选部门列表，列表中每个对象包含id（部门id）、name（部门名称）、number（部门人数）
                }
                */
                let valid_data = [];
                if(result.users&&Array.isArray(result.users)){
                    for (let i of result.users) {
                    	i['user_id'] = i.emplId;
                    	delete i.emplId;
						valid_data.push(i);
                    }
                }
                result.users = valid_data;
                callback(result);
            },
           onFail : function(err) {
             //错误的话报错
             console.log('code_log',err);
             if(err.errorCode != -1){

             }
           }
        });
    },
	multipleChoose(callback,users=[],selectedUsers=[],disabledUsers=[],maxUsers=1000){
		dd.ready(function(){
			dd.biz.customContact.multipleChoose({
				title: '多选人的标题', //标题
				users: users,//一组员工工号
				corpId: corpId,//企业 ID，
				isShowCompanyName: false,   //true|false，默认为 false
				selectedUsers: selectedUsers, //默认选中的人，注意:已选中不可以取消
				disabledUsers: disabledUsers, //不能选的人
				max: maxUsers, //人数限制
				onSuccess: function(data) {
					/* data结构
                      [{
                        "name": "张三", //姓名
                        "avatar": "http://g.alicdn.com/avatar/zhangsan.png" //头像图片url，可能为空
                        "emplId": '0573', //工号
                       },
                       ...
                      ]
                    */
					let valid_data = [];
					if(data&&Array.isArray(data)){
						for (let i of data) {
							i['user_id'] = i.emplId;
							delete i.emplId;
							valid_data.push(i);
						}
					}
					callback(valid_data);
				},
				onFail : function(err) {}
			});
		});
	}
}
