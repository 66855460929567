import http from "../getData";

export default {
	//获取ddconfig鉴权信息
	getAuthInfo(params){
		return http.post('company/getAuthInfo', params);
	},
	//获取企业列表
	getCompanyList(params){
		return http.post('company/getCompanyList', params);
	},
	//同步企业(同步部门)
	syncAllDepartmentForCompany(params){
		return http.post('company/syncAllDepartmentForCompany', params);
	}
}
