<template>
	<div class="people-box" ref="peopleBox">
		<div class="people-one" :class="{can_move:!disabled}" v-for="(one,index) in checkedPeople" :key="index" :data-index="index">
			<img class="avatar" :class="{can_move:!disabled}" :src="one.avatar" alt="" v-if="one.avatar" />
			<span class="avatar" :class="{can_move:!disabled}" v-else>{{one.name.substr(-2)}}</span>
			<span class="name" :class="{can_move:!disabled}">{{`${one.name.substr(-4)} `}}</span>
			<span class="delete el-icon-circle-close" @click.stop="delete_approval(index)" v-show="!disabled"></span>
		</div>
		<div :class="{stop:disabled}" class="people-one add" @click="selectedDingDingPerson">
			<span class="avatar icon-11"></span>
			<span class="name">添加</span>
		</div>
	</div>
</template>

<script>
    import people from '@/utils/people_picker'
    import api from '@/api/methods'
    export default {
        name: "select-people",
		emits:['people-data'],
        props:{
            userInfo:{
                type:Array,
                default(){
                    return []
                }
            },
			disabled:{
            	type:Boolean,
				default:false
			},
			dept_id:{
            	type:[String,Number],
				default:''
			},
			multiple:{
            	type:Boolean,
				default:true
			}
        },
        data(){
            return {
                checkedPeople:[],
				users:[]
            }
        },
		watch:{
        	userInfo:{
        		handler(val){
					this.initData();
				},
				deep:true
			},
			dept_id:{
        		handler(val){
					this.initData();
				}
			}
		},
		mounted(){
			this.initData();
		},
        methods:{
        	initData(){
				this.checkedPeople = JSON.parse(JSON.stringify(this.userInfo));
				this.getUsers();
			},
			getUsers() {
				let p ={
					page:1,
					page_size:999
				};
				if(this.dept_id){
					p.dept_id = this.dept_id;
				}
				api.user.getUserList(p).then((res)=>{
					if(res.code==0){
						if(Array.isArray(res.data.list)){
							this.users = res.data.list;
						}
					}
				})
			},
			delete_approval(index){
				this.checkedPeople.splice(index,1);
				this.save();
			},
            deal_with_data(person){
				if(this.disable){
					return;
				}
				if(!this.multiple){
					if(this.checkedPeople.map(i=>i.user_id).filter(item=>item==person.user_id).length==0){
						this.checkedPeople=[person];
					}else{
						this.checkedPeople=[];
					}
				}else{
					if(this.checkedPeople.map(i=>i.user_id).filter(item=>item==person.user_id).length==0){
						this.checkedPeople.push(person);
					}else{
						this.checkedPeople.forEach((item,index)=>{
							if(item.user_id==person.user_id){
								this.checkedPeople.splice(index,1);
							}
						});
					}
				}
                this.disabled=false;
            },
            selectedDingDingPerson(){
            	if(this.disabled){
            		return;
				}
                people.multipleChoose((data)=>{
                    this.checkedPeople=[];
                    for (let i of data){
                        this.checkedPeople.push(i);
                    }
                    this.save();
                },this.users.map(one=>one.user_id));
            },
			save(){
				this.$emit('people-data',this.checkedPeople);
			},
        }
    }
</script>
<style scoped lang="less">
	.people-box{
		flex: 1;
		display: inline-flex;
		flex-wrap: wrap;
		overflow: hidden;
		margin-top: .1rem;
		-webkit-user-select: none;
		.people-one{
			position: relative;
			width:.6rem;
			display: flex;
			flex-direction: column;
			align-items:center;
			margin-bottom: .1rem;
			.avatar{
				width: .4rem;
				height: .4rem;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
				background-color: #2aa0f7;
				border-radius: 50%;
				font-size: .12rem;
			}
			.name{
				font-size: .12rem;
			}
			.delete{
				position: absolute;
				display: none;
				right: .02rem;
				top: 0;
				z-index: 1;
				cursor: pointer;
			}
			&:hover{
				.delete{
					display: inline-flex;
				}
			}
		}
		.add{
			cursor: pointer;
			.avatar{
				background-color: #eef2f5;
				color: #333;
			}
			.name{
				color: #a7b6cb;
			}
			&:active{
				opacity: .8;
			}
		}
		.stop{
			cursor: not-allowed;
			opacity: .7;
		}
	}
</style>
