export default {
    namespaced: true,
    state: {
       current_user:{
           "avatar":"https://static-legacy.dingtalk.com/media/lADPGqGobhWO95_NAkDNAkA_576_576.jpg",
           "name":"Test",
           "union_id":"",
           "dept_id":"123",
           "mobile":"",
           "corp_id":"",
           "corp_name":"杭州快说快说快",
           "app_admin":0
       }
    },
    mutations: {
        current_user(state,data){
            Object.assign(state.current_user,data);
        }
    }
}
