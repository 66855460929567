import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import '@vant/touch-emulator';
import noData from '@/components/no-data.vue';
import vue3PhotoPreview from 'vue3-photo-preview';
import selectPeople from '@/components/select-people.vue';
import popup from '@/components/popup.vue';
import newInput from '@/components/input/new-input.vue';
import newSelect from '@/components/new-select.vue';
import loading from "@/components/loading.vue"
import 'vue3-photo-preview/dist/index.css';
const app = createApp(App);
app.use(vue3PhotoPreview);
app.use(store);
app.use(router);
app.use(Vant);
app.component('select-people',selectPeople);
app.component('new-input',newInput);
app.component('new-select',newSelect);
app.component('popup',popup);
app.component('no-data',noData);
app.component('loading',loading);
app.mount('#app');
