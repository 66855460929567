import * as dd from "dingtalk-jsapi";
import myPublic from "@/utils/public"
import route from "@/router"
export default {
	listenLeftBack(){
		if(dd.version){
			dd.ready(function(){
				if(!dd.ios){
					document.addEventListener('backbutton', function(e){
						if(myPublic.backList.length!=0){
							myPublic.backList[myPublic.backList.length-1].call();
							myPublic.removeBackFunction();
							e.preventDefault()
						}else{
							if(route.currentRoute.path == '/'){
								dd.biz.navigation.close();
								e.preventDefault()
							}
						}
					}, false)
				}else if(dd.ios){
					dd.biz.navigation.setLeft({
						control: true,//是否控制点击事件
						text: '返回',//控制显示文本
						onSuccess : function(result) {
							if(myPublic.backList.length!=0){
								myPublic.backList[myPublic.backList.length-1].call();
								myPublic.removeBackFunction();
							}else{
								if(route.currentRoute.path=='/'){
									dd.biz.navigation.close()
								}else{
									dd.biz.navigation.goBack({
										onSuccess : function(result) {
											/*result结构
                                             {}
                                             */
										},
										onFail : function(err) {
											console.log('err',JSON.stringify(err));
										}
									})
									//返回上一页所在的内容
									//self.$router.go(-1)
								}
							}
						},
						onFail:function(){
							console.log('err',JSON.stringify(err));
						}
					});
				}
			})
		}
	},
}
