<template>
    <div class="loadBox">
		<van-loading :color="color" :text-color="color"/>
    </div>
</template>

<script>
    export default {
        name: "loading",
        props:{
            color:{
                default:'#0094ff',
                type:String
            }
        }
    }
</script>

<style scoped>
    .loadBox{
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
    }
</style>
