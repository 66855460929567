<template>
    <van-popup
            v-model:show="show"
            :round="round"
            position="bottom"
            :overlay-style="{backgroundColor:`rgba(0,0,0,.3)`}"
            :style="{ height: height }"
            @close="close"
    >
        <div class="container">
            <p class="border" v-show="showTop"></p>
            <div class="container-header" v-if="title">
                <div class="title">
                    <span>{{title}}</span>
                    <slot name="title_right"></slot>
                </div>
            </div>
            <div class="container-body">
                <slot name="content"></slot>
            </div>
        </div>
    </van-popup>
</template>

<script>
	import {defineComponent} from "vue"
	import myPublic from '@/utils/public'
    export default defineComponent({
        name: "popup",
        emits:['update:modelValue','close'],
        props: {
            modelValue: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: ''
            },
			round: {
                type: Boolean,
                default: true
            },
			showTop: {
                type: Boolean,
                default: true
            },
            height: {
                type: String,
                default: '40%'
            },
			teleport: {
                type: String,
                default: ''
            }
        },
		data(){
        	return {
        		show:false
			}
		},
		watch:{
			modelValue(val){
				if (val){
					myPublic.pushBackFunction(()=>{
						this.close();
					});
				}else{
					myPublic.removeBackFunction();
				}
				this.show = val;
			}
		},
        methods:{
            close(){
                this.$emit('update:modelValue',false);
                this.$emit('close',false);
            }
        }
    })
</script>

<style scoped lang="less">
    .container {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .border {
            width: .6rem;
            height: .06rem;
            background-color: #333a44;
            border-radius: 2em;
            margin: .08rem auto 0;
        }
        .container-header {
            flex-shrink: 0;
            height: .5rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            overflow: hidden;
			padding: 0 .25rem;
            .title {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #333a44;
                font-size: .2rem;
                font-weight: bolder;
            }
        }

        .container-body {
            flex: 1;
			overflow: hidden;
        }
    }
</style>
