export default {
	data(){
		return{
			isValid:true
		}
	},
	methods:{
		// 构造函数，变量为15位或者18位的身份证号码
		clsIDCard(CardNo) {
			let Valid = false;
			let ID15 = '';
			let ID18 = '';
			let Local = '';
			let pattern;
			// 设置身份证号码，15位或者18位
			 function SetCardNo(CardNo) {
				ID15 = '';
				ID18 = '';
				Local = '';
				CardNo = CardNo.replace(" ", "");
				let strCardNo;
				if (CardNo.length == 18) {
					pattern = /^\d{17}(\d|x|X)$/;
					if (pattern.exec(CardNo) == null) return false;
					strCardNo = CardNo.toUpperCase();
				} else {
					pattern = /^\d{15}$/;
					if (pattern.exec(CardNo) == null) return false;
					strCardNo = CardNo.substr(0, 6) + '19' + CardNo.substr(6, 9)
					strCardNo += GetVCode(strCardNo);
				}
				Valid = CheckValid(strCardNo);
				return Valid;
			};
			// 校验身份证有效性
			function IsValid() {
				return Valid;
			};
			// 返回生日字符串，格式如下，1981-10-10
			function GetBirthDate() {
				let BirthDate = '';
				if (Valid) BirthDate = GetBirthYear() + '-' + GetBirthMonth() + '-' + GetBirthDay();
				return BirthDate;
			};
			// 返回生日中的年，格式如下，1981
			function GetBirthYear() {
				let BirthYear = '';
				if (Valid) BirthYear = ID18.substr(6, 4);
				return BirthYear;
			};
			// 返回生日中的月，格式如下，10
			function GetBirthMonth() {
				let BirthMonth = '';
				if (Valid) BirthMonth = ID18.substr(10, 2);
				if (BirthMonth.charAt(0) == '0') BirthMonth = BirthMonth.charAt(1);
				return BirthMonth;
			};
			// 返回生日中的日，格式如下，10
			function GetBirthDay() {
				let BirthDay = '';
				if (Valid) BirthDay = ID18.substr(12, 2);
				return BirthDay;
			};
			// 返回性别，1：男，0：女
			function GetSex() {
				let Sex = '';
				if (Valid) Sex = ID18.charAt(16) % 2;
				return Sex;
			};
			// 返回15位身份证号码
			function Get15() {
				let ID15 = '';
				if (Valid) ID15 = ID15;
				return ID15;
			};
			// 返回18位身份证号码
			function Get18() {
				let ID18 = '';
				if (Valid) ID18 = ID18;
				return ID18;
			};
			// 返回所在省，例如：上海市、浙江省
			function GetLocal() {
				let Local = '';
				if (Valid) Local = Local;
				return Local;
			};
			function GetVCode(CardNo17) {
				let Wi = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2, 1);
				let Ai = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
				let cardNoSum = 0;
				for (let i = 0; i < CardNo17.length; i++) cardNoSum += CardNo17.charAt(i) * Wi[i];
				let seq = cardNoSum % 11;
				return Ai[seq];
			};
			function CheckValid(CardNo18) {
				if (GetVCode(CardNo18.substr(0, 17)) != CardNo18.charAt(17)) return false;
				if (!IsDate(CardNo18.substr(6, 8))) return false;
				let aCity = {
					11: "北京",
					12: "天津",
					13: "河北",
					14: "山西",
					15: "内蒙古",
					21: "辽宁",
					22: "吉林",
					23: "黑龙江 ",
					31: "上海",
					32: "江苏",
					33: "浙江",
					34: "安徽",
					35: "福建",
					36: "江西",
					37: "山东",
					41: "河南",
					42: "湖北 ",
					43: "湖南",
					44: "广东",
					45: "广西",
					46: "海南",
					50: "重庆",
					51: "四川",
					52: "贵州",
					53: "云南",
					54: "西藏 ",
					61: "陕西",
					62: "甘肃",
					63: "青海",
					64: "宁夏",
					65: "新疆",
					71: "台湾",
					81: "香港",
					82: "澳门",
					91: "国外"
				};
				if (aCity[parseInt(CardNo18.substr(0, 2))] == null) return false;
				ID18 = CardNo18;
				ID15 = CardNo18.substr(0, 6) + CardNo18.substr(8, 9);
				Local = aCity[parseInt(CardNo18.substr(0, 2))];
				return true;
			};
			function IsDate(strDate) {
				let r = strDate.match(/^(\d{1,4})(\d{1,2})(\d{1,2})$/);
				if (r == null) return false;
				let d = new Date(r[1], r[2] - 1, r[3]);
				return (d.getFullYear() == r[1] && (d.getMonth() + 1) == r[2] && d.getDate() == r[3]);
			};
			if (CardNo != null) {
				return SetCardNo(CardNo);
			}
		},
		validCard(idCard) {
			if (!this.clsIDCard(idCard)) {
				this.$toast({message:"输入的身份证号无效,请输入真实的身份证号！",type:'fail '});
				this.isValid = false;
				return false;
			}else{
				this.isValid = true;
				return true;
			}
		},
		validEmail(obj) {
			//对电子邮件的验证
			let myreg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
			if (!myreg.test(obj)) {
				this.isValid = false;
				this.$toast({message:'请输入有效的邮箱！',type:'fail '});
				return false;
			}else{
				this.isValid = true;
				return true;
			}
		},
		validMobile(mobile){
			let myreg = /^(((13[0-9])|(14[0-9])|(15[0-9])|(16[0-9])|(17[0-9])|(18[0-9])|(19[0-9]))+\d{8})$/;
			if(!myreg.test(mobile)){
				this.isValid = false;
				this.$toast({message:'请输入有效的11位手机号码！',type:'fail '});
				return false;
			}else{
				this.isValid = true;
				return true;
			}
		}
	}
}
