import http from "../getData";

export default {
	//实时数据
	processDataList(params){
		return http.post('process/processDataList', params);
	},
	//实时数据详情
	processDataDetail(params){
		return http.post('process/processDataDetail', params);
	},
	//报警数据
	warningDataList(params){
		return http.post('process/warningDataList', params);
	}
}
