<template>
  <router-view v-slot="{Component}">
    <component :is="Component">
    </component>
  </router-view>
</template>

<style lang="less">
  @import url('./assets/public.css');
  @import url('./assets/icon.css');
  #app {
    position: relative;
    width: 100vw;
    height: 100vh;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    font-size: .14rem;
  }
</style>
