export default {
	//导出自行监测填报模板(气、水)
	exportOne(){
		return 'export/exportOne';
	},
	//导出环境统计填报模板(气)
	exportTwo(){
		return 'export/exportTwo';
	},
	//导出环境统计填报模板(水)
	exportThree(){
		return 'export/exportThree';
	}
}
