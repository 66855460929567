<template>
	<div :style="style" ref="input" class="select-value" :class="{disabled:disabled}">
		<input v-model="value" @focus="focus" class="input" :class="{value:multiple&&modelValue.length>0||!multiple&&modelValue}" type="text" :placeholder="placeholder" readonly />
		<span class="arrow icon-2" :class="{reverse:is_focus}" @click="focus" v-show="multiple&&modelValue.length==0||!multiple&&!modelValue"></span>
		<span class="arrow icon-10" @click="clear" v-show="clearable&&(multiple&&modelValue.length>0||!multiple&&modelValue)"></span>
	</div>
	<popup v-model="is_focus" height="40vh" :title="title">
		<template #content>
			<div class="select-options" @scroll="scroll">
				<p class="option-one" v-show="multiple" @click="isAll">
					<span>全选</span>
					<span class="icon" :class="checkedValue.length>0&&list.length<=checkedValue.length?'selected icon-21':'icon-20'"></span>
				</p>
				<p :class="{selected:isSelect(item)}" class="option-one" v-for="(item,index) in list" :key="index" @click="click(item)">
					<span>{{item[defaultProp.name]}} <span class="tip" v-if="showTip">{{item[defaultProp.tip]}}</span></span>
					<span class="icon" :class="isSelect(item)?'icon-21':'icon-20'" v-show="multiple" ></span>
				</p>
				<p class="van-safe-area-bottom"></p>
			</div>
		</template>
		<template #title_right>
			<slot name="title_right" :value="checkedValue"></slot>
		</template>
	</popup>
</template>

<script>
    export default {
        name: "new-select",
		emits:["update:modelValue","change","requestList"],
		props:{
			modelValue:{
				type:[String,Number,Array]
			},
			list:{
				type:Array,
				default(){
					return [];
				}
			},
			defaultProp:{
				type:Object,
				default(){
					return {
						name:'name',
						value:'ID',
						tip:'tip',
					};
				}
			},
			clearable:{
				type:Boolean,
				default:true
			},
			disabled:{
				type:Boolean,
				default:false
			},
			showTip:{
				type:Boolean,
				default:false
			},
			multiple:{
				type:Boolean,
				default:false
			},
			placeholder:{
				type:String,
				default:''
			},
			title:{
				type:String,
				default:''
			},
			style:{
			}
		},
		data(){
        	return {
				is_focus:false,
				width:300,
				checkedValue:''
			}
		},
		computed:{
			value:{
				set(val){
					if(!this.multiple){
						this.$emit('update:modelValue',this.checkedValue);
						this.$emit('change',this.checkedValue);
					}
				},
				get(){
					let modelValue = this.multiple?JSON.parse(JSON.stringify(this.modelValue)):[this.modelValue];
					return this.list.filter(one=>modelValue.find(sec=>sec==one[this.defaultProp.value])).length>0?this.list.filter(one=>modelValue.find(sec=>sec==one[this.defaultProp.value])).map(one=>one[this.defaultProp.name]).join('、'):this.modelValue;
				}
			}
		},
		created(){
			if(!this.multiple){
				this.checkedValue = '';
			}else{
				this.checkedValue = [];
			}
		},
		methods:{
			scroll(e) {
				if (e.target.clientHeight + e.target.scrollTop + 1 > e.target.scrollHeight) {
					this.$emit('scroll',e)
				}
			},
			focus(e){
				if(!this.disabled){
					this.is_focus = true;
					if(!this.multiple){
						this.checkedValue = '';
					}else{
						this.checkedValue = [];
					}
					this.checkedValue = JSON.parse(JSON.stringify(this.modelValue));
					console.log('this.modelValue',this.modelValue);
				}
				this.$emit('requestList',1);
			},
			blur(){
				setTimeout(()=>{
					this.is_focus = false;
				},300)
			},
			click(item){
				if(!this.multiple){
					this.checkedValue = item[this.defaultProp.value];
					this.$emit('update:modelValue',this.checkedValue);
					this.$emit('change',this.checkedValue);
					setTimeout(()=>{
						this.is_focus = false;
					},300)
				}else{
					if(!Array.isArray(this.checkedValue)){
						this.checkedValue = [];
					}
					if(this.checkedValue.find(one=>one==item[this.defaultProp.value])){
						this.checkedValue.splice(this.checkedValue.findIndex(one=>one==item[this.defaultProp.value]),1)
					}else{
						this.checkedValue.push(item[this.defaultProp.value]);
					}
				}
			},
			clear(){
				if(!this.multiple){
					this.checkedValue = '';
				}else{
					this.checkedValue = [];
				}
				this.$emit('update:modelValue',this.checkedValue);
				this.$emit('change',this.checkedValue);
			},
			isSelect(item){
				if(!this.multiple){
					return item[this.defaultProp.value]==this.checkedValue;
				}else{
					return this.checkedValue.find(one=>one==item[this.defaultProp.value]);
				}
			},
			isAll(){
				if(this.checkedValue.length>0&&this.list.length<=this.checkedValue.length){
					this.checkedValue = [];
				}else{
					this.checkedValue = this.list.map(one=>one[this.defaultProp.value]);
				}
			}
		}
    }
</script>
<style scoped lang="less">
	.select-value{
		position: relative;
		flex: 1;
		background-color: #eef2f5;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: .32rem;
		border-radius: .04rem;
		cursor: pointer;
		overflow: hidden;
		.input{
			position: relative;
			flex: 1;
			padding: 0 .1rem;
			background-color: #eef2f5;
			border:none;
			font-size: .12rem;
			&::placeholder{
				font-size:.12rem!important;
			}
		}
		.arrow{
			position: absolute;
			right: 0;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: .3rem;
			color: #ccc;
			transition: all .3s;
			font-size: .12rem;
		}
		.reverse{
			transform: rotate(180deg);
		}
		&:hover{
			border-color:#c0c4cc;
		}
	}
	.select-options{
		width: calc(100% - .5rem);
		height: 100%;
		background-color: #fff;
		overflow-y: auto;
		padding: 0 .25rem;
		.option-one{
			display: flex;
			align-items: center;
			white-space: nowrap;
			text-overflow: ellipsis;
			justify-content: space-between;
			overflow: hidden;
			height: .4rem;
			cursor: pointer;
			border-bottom: 1px solid #efefef;
			.tip{
				color: #999;
			}
		}
		.selected{
			color: #2aa0f7;
		}
	}
	.disabled{
		background-color: #f5f7fa;
		border-color:#dcdfe6 !important;
		.input{
			cursor: not-allowed;
			background-color: #f5f7fa;
		}
	}
</style>
