import user from './user';
import company from './company';
import device from './device';
import device_warning from './device_warning';
import process from './process';
import export_file from './export';
export default {
	user:user,
	company:company,
	device:device,
	device_warning:device_warning,
	process:process,
	export_file:export_file,
}
