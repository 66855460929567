import * as dd from 'dingtalk-jsapi';
import myPublic from '@/utils/public'
import initEvent from '@/utils/initEvent'
import api from "@/api/methods/index"
import store from "@/store"
const corp_id =  env.corp_id;
export default {
	getAuthInfo(callBack = () => { }) {
		store.state.app.message = "获取认证信息...";
		return api.company.getAuthInfo({
			"corp_id": corp_id.toString(),
			"url": window.location.href.split("#")[0].toString()
		}).then((res) => {
			if (res.code == 0) {
				store.state.app.firstGo += 1;
				store.state.app.message = "已获取认证信息";
				myPublic.setStorage('cf-auth_info', res.data.auth_info);
				this.ddConfig();
				callBack(true);
			}
		})
	},
	ddConfig() {
		let info = myPublic.getStorage('cf-auth_info');
		store.state.app.message = '获取鉴权之前...';
		dd.config({
			agentId: info.agentId, // 必填，微应用ID（npm下载包dd鉴权参数名agentId已换成appId！！！）
			corpId: info.corpId,//必填，企业ID
			timeStamp: info.timestamp, // 必填，生成签名的时间戳
			nonceStr: info.nonceStr, // 必填，自定义固定字符串。
			signature: info.signature, // 必填，签名
			type: 0,   //选填。0表示微应用的jsapi,1表示服务窗的jsapi；不填默认为0。该参数从dingtalk.js的0.8.3版本开始支持
			jsApiList: [
				'runtime.info',
				'runtime.permission.requestAuthCode',
				'device.notification.prompt',
				'biz.chat.pickConversation',
				'device.notification.confirm',
				'device.notification.alert',
				'device.notification.prompt',
				'device.audio.startRecord',
				'device.audio.stopRecord',
				'device.audio.onRecordEnd',
				'device.audio.download',
				'device.audio.play',
				'device.audio.pause',
				'device.audio.resume',
				'device.audio.stop',
				'device.audio.onPlayEnd',
				'device.audio.translateVoice',
				'biz.chat.open',
				'biz.util.open',
				'biz.user.get',
				'biz.chat.openSingleChat',
				'biz.contact.choose',
				'biz.customContact.multipleChoose',
				'biz.telephone.call',
				'biz.util.uploadImage',
				'biz.ding.create',
				'biz.ding.post',
				'biz.intent.fetchData',
				'biz.contact.complexPicker',
				'device.notification.alert',
				'device.geolocation.get'
			] // 必填，需要使用的jsapi列表，注意：不要带dd。
		});
		dd.userid = 0;
		initEvent.listenLeftBack();
		dd.error(function (err) {
			store.state.app.message = '获取鉴权失败...';
			console.error('dd error: ' + JSON.stringify(err));
		})//该方法必须带上，用来捕获鉴权出现的异常信息，否则不方便排查出现的问题
	},
	userLogin(code, callBack = () => { }) {
		store.state.app.message = "获取token之前";
		api.user.userLogin({
			"corp_id": corp_id,
			"code": code
		}).then(async (res) => {
			if (res.code == 0) {
				store.state.app.firstGo += 1;
				store.state.app.message = "已获取token";
				myPublic.setStorage('cf-token', res.data.token, res.data.expire_second);
				store.commit('app/token', res.data.token);
				store.state.app.message = "跳转至首页";
				callBack(true)
			}
		})
	},
	requestAuthCode(callBack = () => { }) {
		dd.ready(() => {
			store.state.app.message = "获取钉钉授权码之前...";
			dd.runtime.permission.requestAuthCode({
				corpId: corp_id, // 企业id
				onSuccess: (_info) => {
					store.state.app.message = "已获取钉钉授权码";
					this.userLogin(_info.code, callBack)// 通过该免登授权码可以获取用户身份
				}
			});
		})
	},
}
