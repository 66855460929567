import axios from 'axios';
import { Toast } from 'vant';
import myPublic from '@/utils/public';
import store from '@/store';
import qs from 'qs';
let id = 0;
const API_HOST = env.API_HOST;
const list = [
	'company/getAuthInfo',
	'user/userLogin'
];
axios.interceptors.request.use(config => {
    // loading
    return config
}, error => {
    return Promise.reject(error)
});

axios.interceptors.response.use(response => {
    // console.log('响应拦截器',response)
    return response
}, error => {
    return Promise.resolve(error.response)
});

function checkStatus(response,url,record) {
    // loading
    // 如果http状态码正常，则直接返回数据
    if (response && (response.status === 200 || response.status === 304 || response.status === 400)) {
		console.log(`response`,record,url+' ',response.data);
		console.timeEnd(`request ${record}`);
        return response.data
        // 如果不需要除了data之外的数据，可以直接 return response.data
    }
    // 异常状态下，把错误信息返回去
	console.err(`response`,record,url+' ',response);
	console.timeEnd(`request ${record}`);
    return {
        status: -404,
        msg: '网络异常或服务端出错'
    }
}
// 验证code
function checkCode(res,url) {
    // 如果code异常(这里已经包括网络错误，服务器错误，后端抛出的错误)，可以弹出一个错误提示，告诉用户
    if (res.status === -404) {
		Toast.fail({
			message: res.msg +'方法名：'+ url || '服务器出错 方法名：'+ url,
		})
    }
	if (res.code == 1000) {
		if (myPublic.getUrlKey('tab')) {
			sessionStorage.setItem('cf-tab',myPublic.getUrlKey('tab'));
		}
		localStorage.removeItem('cf-token');
		store.state.app.firstGo = 0;
		location.href = location.origin+location.pathname+'#/login';
		return res
	}
	if(res.code!=0){
		Toast.fail({
			message: res.msg,
		})
		return res
	}
    return res
}

export default {
    post(url,data, config = { 'Content-Type': 'application/json; charset=UTF-8' }){
    	let record = id;
		if(list.indexOf(url)==-1){
			let token = myPublic.getStorage('cf-token');
			url = url+`?access_token=${token}`;
		}
		console.log(`request`,record,url+' ',data);
		console.time(`request ${record}`);
		id++;
        return axios({
            method: 'post',
            baseURL: API_HOST,
            url,
            data: JSON.stringify(data),
            headers: config
        }).then(response =>{
            return checkStatus(response,url,record);
        }).then( res =>{
            return checkCode(res, url)
        }).catch(function(err) {
			console.log(`err`,record,url+' ',err);
			id++;
        })
    },
    get(url,data, config = { 'Content-Type': 'application/x-www-form-urlencoded' }){
    	let record = id;
		if(list.indexOf(url)==-1){
			let token = myPublic.getStorage('cf-token');
			data["access_token"] = token;
		}
		console.log(`request`,record,url+' ',data);
		console.time(`request ${record}`);
		id++;
        return axios({
            method: 'get',
            baseURL: API_HOST,
			header:config,
            url,
			params:data,
			responseType:'blob',
        }).then(response =>{
        	const res = {
        		code:0,
				data:checkStatus(response,url,record)
			};
            return res;
        }).then(res =>{
            return checkCode(res, url);
        }).catch(function(err) {
			console.log(`err`,record,url+' ',err);
			id++;
        })
    },
	upload(url,data, config = { 'Content-Type': 'multipart/form-data' }){
		let record = id;
		console.log(`request`,record,url+' ',data);
		console.time(`request ${record}`);
		id++;
        return axios({
            method: 'post',
            baseURL: API_HOST,
            url,
            data: data,
            headers: config
        }).then(response =>{
            return checkStatus(response,url,record);
        }).then( res =>{
            return checkCode(res, url)
        }).catch(function(err) {
			console.log(`err`,record,url+' ',err);
			id++;
        })
    },
}
