export default {
	namespaced: true,
	state: {
		firstGo: 0, // 是否时第一次进来
		message:'',
		token: '', // token
		ossInfo:{}, // 保存oss信息
		oss_expire:'', // 保存oss过期时间
		tabs:[
			{
				name:'工作台',
				path:'/home',
				index:'1'
			},{
				name:'工作台',
				path:'/home',
				index:'2'
			}
		],
		activeTab:'1',
	},
	mutations: {
		token(state,data){
			state.token = data;
		},
		OSSINFO(state,data){
			state.ossInfo = data;
		},
		OSSEXPIRE(state,data){
			state.oss_expire = data;
		},
		activeTab(state,data){
			state.activeTab = data;
			state.asideShow = true;
		}
	}
}
