import http from "../getData";

export default {
	//添加设备
	addDevice(params){
		return http.post('device/addDevice', params);
	},
	//设备详情
	deviceDetail(params){
		return http.post('device/deviceDetail', params);
	},
	//修改映射数据
	mappingEdit(params){
		return http.post('device/mappingEdit', params);
	},
	//编辑设备
	deviceEdit(params){
		return http.post('device/deviceEdit', params);
	},
	//删除设备
	deviceDelete(params){
		return http.post('device/deviceDelete', params);
	},
	//设备列表
	deviceList(params){
		return http.post('device/deviceList', params);
	},
	//添加设备数据映射关系
	addDeviceMapping(params){
		return http.post('device/addDeviceMapping', params);
	},
	//根据设备id获取映射关系
	getMappingByIds(params){
		return http.post('device/getMappingByIds', params);
	},
	//根据类型获取数据映射
	getMappingDetail(params){
		return http.post('device/getMappingDetail', params);
	},
	//获取实时数据映射关系
	getSourceData(params){
		return http.post('device/getSourceData', params);
	},
	//获取小时源数据映射
	getHourSourceData(params){
		return http.post('device/getHourSourceData', params);
	},
	//获取天源数据映射
	getDaySourceData(params){
		return http.post('device/getDaySourceData', params);
	}
}
