import http from "../getData";

export default {
	//添加报警规则
	addDeviceWarning(params){
		return http.post('device_warning/addDeviceWarning', params);
	},
	//更新报警规则
	deviceWarningUpdate(params){
		return http.post('device_warning/deviceWarningUpdate', params);
	},
	//删除报警规则
	deviceWarningDelete(params){
		return http.post('device_warning/deviceWarningDelete', params);
	},
	//报警规则详情
	deviceWarningDetail(params){
		return http.post('device_warning/deviceWarningDetail', params);
	},
	//报警规则列表
	deviceWarningList(params){
		return http.post('device_warning/deviceWarningList', params);
	},
	//根据设备ID获取映射条件
	getMappingByDeviceId(params){
		return http.post('device_warning/getMappingByDeviceId', params);
	}
}
