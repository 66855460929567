<template>
	<div class="noData" :style="styles">
		<div class="noDataBox">
			<img class="img" src="@/assets/svg/noData.svg" alt="">
			<p class="title">暂无内容</p>
		</div>
	</div>
</template>

<script>
    export default {
        name: "no-data",
		props:{
        	styles:{
        		type:Object,
				default(){
        			return {
						height: '100%'
					}
				}
			}
		}
    }
</script>

<style scoped lang="less">
	.noData {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		.noDataBox {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			.img{
				width: 1.4rem;
			}
			.title {
				color: #b2caf0;
				text-align: center;
				height: .4rem;
				line-height: .4rem;
			}
		}
	}
</style>
