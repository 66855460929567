import {createStore} from 'vuex'
import app from './module/app'
import user from './module/user'
const store = createStore({
	modules: {
		app: app,
		user: user
	}
});
export default store
