import http from '../getData';
export default {
	//用户登录获取token
	userLogin(params){
		return http.post('user/userLogin', params);
	},
	//获取当前登录人员信息
	getUserDetail(params){
		return http.post('user/getUserDetail', params);
	},
	//获取用户列表
	getUserList(params){
		return http.post('user/getUserList', params);
	},
	//设置用户为管理员
	setUserForAdmin(params){
		return http.post('user/setUserForAdmin', params);
	},
	//设置用户为企业成员
	setUserForMember(params){
		return http.post('user/setUserForMember', params);
	},
	//删除用户
	deleteUser(params){
		return http.post('user/deleteUser', params);
	},
	//新增用户
	addUser(params){
		return http.post('user/addUser', params);
	}
}
