<template>
	<div class="new-input">
		<input @compositionstart="handleCompositionStart"
			   @compositionend="handleCompositionEnd" class="input" :readonly="disabled" ref="input" :class="{isValid:!isValid}" v-model="value" @input="input" :placeholder="placeholder" @change="change" @blur="change" />
		<p v-show="!isValid" :class="type"></p>
	</div>

</template>

<script>
import form_valid from './formValid'

export default {
	name: "new-input",
	emits:["update:modelValue","change","input"],
	mixins: [form_valid],
	props: {
		placeholder: {
			type: String,
			default: '请输入内容'
		},
		modelValue: {
			type: [String,Number]
		},
		type: {
			type: String,
			default: 'text'
		},
		disabled: {
			type: Boolean,
			default: false
		},
	},
	data(){
		return {
			value:'',
			isComposing:false
		}
	},
	watch:{
		modelValue:{
			handler(val){
				this.value = val;
			},
			immediate:true
		}
	},
	mounted() {
		this.isValid = true;
	},
	methods: {
		handleCompositionStart() {
			this.isComposing = true;
		},
		handleCompositionEnd(event) {
			this.isComposing = false;
			this.input(event)
		},
		input(e) {
			if(this.isComposing) return;
			if (this.type == 'number') {
				e.target.value = /^-?\d*|^\d*/.exec(e.target.value)[0];
				e.target.value = e.target.value?Number(e.target.value):0;
			}
			this.$emit('update:modelValue',e.target.value);
			this.$emit('update:value',e.target.value);
		},
		change(e) {
			if(this.isComposing) return;
			let val = e.target.value;
			if (this.type == 'email' && !this.validEmail(val)) {
				return false;
			} else if (this.type == 'telephone' && !this.validMobile(val)) {
				return false;
			} else if (this.type == 'IDCard' && !this.validCard(val)) {
				return false;
			}
			this.input(e);
			return true;
		},
	}
}
</script>
<style scoped lang="less">
	.new-input{
		width: 100%;
		display: flex;
		flex-direction: column;
		min-height: .32rem;
		.isValid{
			border-color:red;
		}
		.input{
			flex: 1;
			padding:0 .1rem;
			border-radius: .04rem;
			background-color: #eef2f5;
			font-size:.12rem;
		}
		.input::placeholder{
			font-size: .12rem;
		}
		.email::before{
			content:'请输入有效的邮箱！';
			position: absolute;
			color:red;
			font-size: 12px;
		}
		.telephone::before{
			content:'请输入有效的手机号！';
			position: absolute;
			color:red;
			font-size: 12px;
		}
		.IDCard::before{
			content:'请输入有效的身份证号！';
			position: absolute;
			color:red;
			font-size: 12px;
		}
	}
</style>
