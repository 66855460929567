import * as dd from 'dingtalk-jsapi';
import store from '@/store'
import api from '@/api/methods'
import router from '@/router/index'

export default {
	removeStorage(key) {
		localStorage.removeItem(key);
	},
	setStorage(key, value, expire = 86400) {
		localStorage.setItem(key, JSON.stringify({
			value: value,
			now: Date.now(),
			expire: expire
		}))
	},
	getStorage(key) {
		if (localStorage.getItem(key)) {
			let obj = this.isJsonString(localStorage.getItem(key)) ? JSON.parse(localStorage.getItem(key)) : {
				value: null,
				now: 0,
				expire: 0
			};
			if (Date.now() > obj.now + obj.expire * 1000) {
				return null;
			} else {
				return obj.value;
			}
		} else {
			return null;
		}
	},
	//判断是否是json字符串
	isJsonString(str) {
		try {
			if (str === null) {
				return false;
			}
			if (typeof JSON.parse(str) == "object") {
				return true;
			}
		} catch (e) {
		}
		return false;
	},
	//判断一个数据是否为空
	isEmpty(str) {
		if (Object.prototype.toString.call(str) === '[object Undefined]') { //空
			return true
		} else if (
			Object.prototype.toString.call(str) === '[object String]' ||
			Object.prototype.toString.call(str) === '[object Array]') { //字条串或数组
			return str.length == 0 ? true : false
		} else if (Object.prototype.toString.call(str) === '[object Object]') {
			return JSON.stringify(str) == '{}' ? true : false
		} else if (Object.prototype.toString.call(str) === '[object Number]') {
			return str == NaN ? true : false
		} else {
			return true
		}
	},
	//获取url参数
	getUrlKey(name) {
		return (
			decodeURIComponent(
				(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
					location.href
				) || [, ""])[1].replace(/\+/g, "%20")
			) || null
		);
	},
	//时间封装
	dateFormat: {
		//日期格式化
		/**
		 * @param date 标准时间日期格式
		 * @param fmt 格式化格式
		 * @returns {string}
		 */
		format(date, fmt = 'YYYY-MM-DD HH:mm:ss') {
			date = new Date(date).getTime();
			date = new Date(date);
			let o = {
				'M+': date.getMonth() + 1,
				'D+': date.getDate(),
				'h+': date.getHours() % 12 === 0 ? 12 : date.getHours() % 12,
				'H+': date.getHours(),
				'm+': date.getMinutes(),
				's+': date.getSeconds(),
				'q+': Math.floor((date.getMonth() + 3) / 3),
				'S': date.getMilliseconds()
			};
			let week = {
				'0': '\u65e5',
				'1': '\u4e00',
				'2': '\u4e8c',
				'3': '\u4e09',
				'4': '\u56db',
				'5': '\u4e94',
				'6': '\u516d'
			};
			if (/(Y+)/.test(fmt)) {
				fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
			}
			if (/(E+)/.test(fmt)) {
				fmt = fmt.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? '\u661f\u671f' : '\u5468') : '') + week[date.getDay() + ''])
			}
			for (let k in o) {
				if (new RegExp('(' + k + ')').test(fmt)) {
					fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
				}
			}
			return fmt
		},
		//格式日期兼容苹果端日期获取
		dateReplace(date) {
			return date.replace(/\-/g, '/');
		}
	},
	backList: [], //用于储存返回的事件
	//添加返回时要操作的事件
	pushBackFunction(fn) {
		this.backList.push(fn);
	},
	//移除最后一个事件
	removeBackFunction() {
		if (this.backList.length == 0) {
			return;
		}
		this.backList.splice(this.backList.length - 1, 1);
	},
	openSlidePanel(url,title){
		dd.biz.util.openSlidePanel({
			url: url, //打开侧边栏的url
			title:title, //侧边栏顶部标题
			onSuccess: function (result) {
				/*
                     调用biz.navigation.quit接口进入onSuccess, result为调用biz.navigation.quit传入的数值
                 */
			},
			onFail: function () {
				/*
                    tips:点击右上角上角关闭按钮会进入onFail
                 */
			}
		})
	},
	getUserDetail(){
		return api.user.getUserDetail({}).then((res) => {
			if (res.code == 0) {
				res.data.corp_name = res.data.corp_info.length>0?res.data.corp_info[0].corp_name:'';
				store.commit('user/current_user', res.data);
				window.initWsMonitor(res.data.name,router);
			}
		});
	}
}
