import {createRouter, createWebHashHistory} from 'vue-router'
import store from '@/store/index'
import api from '@/api/methods/index'
import myPublic from '@/utils/public'
import login from '@/utils/login'
import * as dd from 'dingtalk-jsapi';
const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/views/index.vue'),
        redirect: '/home',
        children: [
            {
                path: 'home',
                name: 'home',
                meta: {
                    prop: 'home'
                },
                component: () => import('@/views/home.vue')
            },
            {
                path: 'login',
                name: 'login',
                meta: {
                    prop: 'login'
                },
                component: () => import('@/views/login.vue')
            },
            {
                path: 'real-data',
                name: 'real-data',
                meta: {
                    prop: 'real-data'
                },
                component: () => import('@/views/real-data.vue')
            },
            {
                path: 'manage',
                name: 'manage',
                meta: {
                    prop: 'manage'
                },
                component: () => import('@/views/manage.vue')
            },
            {
                path: 'export',
                name: 'export',
                meta: {
                    prop: 'export'
                },
                component: () => import('@/views/export.vue')
            }
        ]
    }
];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
});
router.beforeEach(async (to, from, next) => {
    let token = myPublic.getStorage('cf-token')?myPublic.getStorage('cf-token'):myPublic.getUrlKey('token');
    if(store.state.app.firstGo==0&&myPublic.getUrlKey('token')){
        myPublic.setStorage('cf-token',token);
    }
	if (store.state.app.firstGo == 0&&to.name == 'home' && to.query.tab&&!sessionStorage.getItem('cf-tab')) {
		sessionStorage.setItem('cf-tab',to.query.tab);
	}
    if(!token){
        store.state.app.firstGo = 0;
        if(to.path!='/login'){
            next({path:'/login'})
        }else{
            next();
        }
    }else {
        if (store.state.app.firstGo < 1) {
            store.state.app.firstGo += 1;
            store.commit('app/token', token);
        }
        if (store.state.app.firstGo < 2) {
           if(dd.version){
			   await login.getAuthInfo();
		   }
        }
        if (store.state.app.firstGo < 3) {
            store.state.app.firstGo += 1;
            await myPublic.getUserDetail();
        }
		next();
    }
});
router.afterEach((to, from) => {
	if(myPublic.backList.length != 0){
		myPublic.backList = [];
	}
});
export default router
